<template>
  <BasePage :isLoaded="!!(Collections && Collection)" id="collection-page">
    <template v-slot:content>
      <v-container class="collection-page-container py-16">
        <v-row>
          <v-col cols="12" sm="8" offset-sm="2">
            <Cta
              :to="{ name: 'ProductsShowcase', hash: '#collections' }"
              :reverse="true"
              action="internal"
              label="Back"
              class="d-block"
            />
            <h2 class="h1-big -sci-gradient d-block">{{ Collection.title }}</h2>
            <h5 class="h5">{{ Collection.subtitle }}</h5>
          </v-col>
        </v-row>
      </v-container>
      <ModularContent
        v-if="!!(Collection.modularContent || Collection.gallery)"
        :contents="Collection.modularContent"
        :gallery="Collection.gallery"
      />
      <div v-if="!!filteredList">
        <v-container class="py-16">
          <v-row align="center">
            <v-col cols="12" sm="8" offset-sm="2">
              <MarkdownBlock
                :inline="true"
                :text="Collections.collectionProductsTitle"
                tag="h1"
                class="h1 -sci-gradient"
              />
            </v-col>
            <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="2">
              <MarkdownBlock
                :inline="false"
                :text="Collections.collectionProductsDescription"
                tag="div"
              />
            </v-col>
            <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="2">
              <ButtonFilters
                @on-filter-click="filterProducts"
                :selectedFilter="categoryIdFilter"
              />
            </v-col>
          </v-row>
        </v-container>
        <CardList
          :cards="currentList"
          @on-reset-filters="filterProducts"
          :filterTitle="categoryTitleFilter"
        />
        <v-container>
          <v-row align="center" class="buttons-row">
            <v-col cols="3" offset-sm="1">
              <ButtonScrollTo target="collection-page" />
            </v-col>
            <v-col cols="6" sm="4">
              <ButtonLoadMore
                v-if="currentPage < pages"
                label="Load more"
                @click="loadMoreData"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="!!allPackaging.length">
        <v-container class="py-16">
          <v-row align="center">
            <v-col cols="12" sm="8" offset-sm="2">
              <MarkdownBlock
                :inline="true"
                :text="Collections.collectionPackagingTitle"
                tag="h1"
                class="h1 -sci-gradient"
              />
            </v-col>
            <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="2">
              <MarkdownBlock
                :inline="false"
                :text="Collections.collectionPackagingDescription"
                tag="div"
              />
            </v-col>
          </v-row>
        </v-container>
        <CardList :cards="allPackaging" />
      </div>
    </template>
  </BasePage>
</template>

<script>
import { uniqBy } from 'lodash'
import { optimizedImageURL } from '@/services/OptimizedImage'
import { MarkdownBlock } from '@monogrid/vue-lib'
import BasePage from '@/organisms/BasePage'
import GetCollections from '@/graphql/GetCollections.gql'
import Collection from '@/graphql/GetCollection.gql'
import Cta from '@/molecules/Cta.vue'
import CardList from '@/molecules/CardList.vue'
import ButtonScrollTo from '@/atoms/ButtonScrollTo.vue'
import ButtonLoadMore from '@/atoms/ButtonLoadMore.vue'
import ButtonFilters from '@/atoms/ButtonFilters.vue'
import ModularContent from '@/molecules/CollectionModularContent.vue'

export default {
  name: 'CollectionPage',
  apollo: {
    Collections: {
      query: GetCollections,
      variables () {
        return {
          slug: 'globals'
        }
      }
    },
    Collection: {
      query: Collection,
      variables () {
        return {
          slug: this.slug
        }
      },
      async result (res) {
        if (!res.data.Collection) {
          this.$router.push({ name: 'Home' })
          return
        }
        if (res.data.Collection.products) {
          this.allProducts = res.data.Collection.products.filter(product => (!!product && !product.isPrivate))
          this.allProducts = uniqBy(this.allProducts, 'id')
        }
        if (res.data.Collection.packaging) {
          this.allPackaging = res.data.Collection.packaging.filter(packaging => !!packaging)
          this.allPackaging = uniqBy(this.allPackaging, 'id')
        }
      }
    }
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    BasePage,
    Cta,
    CardList,
    ButtonFilters,
    ButtonScrollTo,
    ButtonLoadMore,
    ModularContent,
    MarkdownBlock
  },
  data: () => ({
    categoryTitleFilter: '',
    categoryIdFilter: -1,
    allProducts: [],
    allPackaging: [],
    currentPage: 1,
    limit: 6
  }),
  computed: {
    pages () {
      return Math.ceil(this.filteredList.length / this.limit)
    },
    filteredList () {
      let products = this.allProducts

      if (this.categoryIdFilter !== -1) {
        products = products.filter(product => product.Category.id === this.categoryIdFilter)
      }

      return products
    },
    currentList () {
      return this.filteredList.slice(0, Math.min(this.limit * this.currentPage, this.filteredList.length))
    }
  },
  methods: {
    filterProducts (category) {
      if (category) {
        this.categoryIdFilter = category.id
        this.categoryTitleFilter = category.title
      } else {
        this.categoryIdFilter = -1
        this.categoryTitleFilter = 'All'
      }
      this.currentPage = 1
    },
    loadMoreData () {
      this.currentPage = Math.min(this.pages, (this.currentPage + 1))
    }
  },
  metaInfo () {
    if (this.Collection) {
      this.$events.$emit('global-breadcrumbs', [
        {
          name: 'ProductsShowcase',
          label: 'Virtual Lab'
        }
      ])

      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.Collection.title },
        { vmid: 'description', name: 'description', content: this.Collection.subtitle },
        { vmid: 'ogdescription', property: 'og:description', content: this.Collection.subtitle }
      ]

      if (this.Collection.optimizedImageURL) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.Collection.optimizedImageURL, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.Collection.title,
        meta
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collection-page-container {
  position: relative;
}

h2 {
  margin-top: rem(20px);
}

.products-row {
  padding: rem(100px 0);
  @include breakpoint ('sm-and-down') {
    padding: rem(71px 0);
  }
}

.buttons-row {
  padding: rem(100px) 0;
}

.translate-y {
  &:nth-child(0n+1),
  &:nth-child(3n),
  &:nth-child(4n) {
    transform: translateY(rem(-38px));
    @include breakpoint ('sm-and-down') {
      transform: translateY(0);
    }
  }

  &:nth-child(0n+1),
  &:nth-child(3n) {
    transform: translateY(rem(-38px));
  }
}
</style>
