<template>
  <div>
    <div
      v-for="(content, index) in contents"
      :key="index"
      class="modular-section-content"
    >
      <v-container v-if="content._repeaterType === 'Title and text'">
        <v-row>
          <v-col cols="12" sm="8" offset-sm="2">
            <ModularTitleText ref="contents" v-bind="content" />
          </v-col>
        </v-row>
      </v-container>
      <SliderStep
        v-if="!!(content._repeaterType === 'Gallery' && gallery)"
        title="Discover the Collection"
        :steps="gallery"
        ref="contents"
      />
      <v-container class="px-0 py-16" v-else>
        <v-row no-gutters>
          <v-col cols="12" sm="8" offset-sm="2">
            <VideoPlayer
              v-if="!!(content.video || content.videoFile)"
              :video="(content.video || content.videoFile)"
              :poster="content.videoFilePoster ? content.videoFilePoster.id : null"
              ref="contents"
              @playing="onPlay(index)"
              @pause="onPause(index)"
              @ended="onEnd(index)"
            />
            <v-img
              ref="contents"
              v-else-if="content.image"
              :src="optimizedImageURL(content.image, { width: 1200 })"
              :lazy-src="getLazySrc(content.image)"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { optimizedImageURL, getLazySrc } from '@/services/OptimizedImage'
import ModularTitleText from './modular_content/ModularTitleAndText.vue'
import SliderStep from '@/molecules/SliderStep.vue'
import VideoPlayer from '@/molecules/VideoPlayer.vue'

export default {
  name: 'CollectionModularContent',
  components: {
    ModularTitleText,
    SliderStep,
    VideoPlayer
  },
  props: {
    contents: {
      type: Array,
      required: true
    },
    gallery: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.$events.$on('slider-video-playing', () => {
      if (this.isPlaying) {
        this.$refs.contents[this.isPlaying].pause()
      }
    })
  },
  methods: {
    optimizedImageURL,
    getLazySrc,
    onPlay (i) {
      this.$events.$emit('modular-video-playing')

      if (Number.isFinite(this.isPlaying)) {
        this.$refs.contents[this.isPlaying].pause()
      }
      this.isPlaying = i
    },
    onPause (i) {
      if (this.isPlaying === i) {
        this.isPlaying = false
      }
    },
    onEnd (i) {
      if (this.isPlaying === i) {
        this.isPlaying = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modular-section-content {
  margin: rem(36px) 0;
}
</style>
